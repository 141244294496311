<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <v-img
          :src="appName"
          max-height="30px"
          max-width="125px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <v-img
              contain
              max-width="100%"
              class="auth-3d-group"
              :src="require(`@/assets/images/3d-characters/Welcome page4.png`)"
            />
            <!-- triangle bg -->
            <!-- <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            /> -->

            <!-- tree -->
            <!-- <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img> -->

            <!-- 3d character -->
            <!-- <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div> -->
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Welcome to Cometa Tools! 👋🏻
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account and start the adventure
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      :disabled="loading"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                      :rules="[emailValidator]"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :disabled="loading"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <v-checkbox
                        hide-details
                        :disabled="loading"
                        label="Remember Me"
                        class="mt-0"
                      >
                      </v-checkbox>

                      <!-- forget link -->
                      <!-- <a
                        class="ms-3"
                        href="#"
                      > Forgot Password? </a> -->
                    </div>

                    <v-btn
                      block
                      :loading="loading"
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p class="mb-0 me-2">
                    New on our platform?
                  </p>
                  <a href="/register"> Create an account </a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { emailValidator } from '@core/utils/validation'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import axios from '@axios'
import { useRouter } from '@core/utils'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'

export default {
  setup(props, context) {
    const loginForm = ref(null)
    const { router } = useRouter()

    // const vm = getCurrentInstance().proxy
    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref('')
    const loading = ref(false)

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true

      axios
        .post('/users/login', { email: email.value, password: password.value })
        .then(response => {
          const { token } = response.data

          localStorage.setItem('accessToken', token)

          return response
        })
        .then(() => {
          // axios.get('/auth/me').then(response => {
          //   const { user } = response.data
          //   const { ability: userAbility } = user

          //   vm.$ability.update(userAbility)

          //   localStorage.setItem('userAbility', JSON.stringify(userAbility))

          //   delete user.ability

          //   localStorage.setItem('userData', JSON.stringify(user))

          //   router.push('/')
          // })
          router.push('/')
        })
        .catch(error => {
          context.root.$message.error(error)
          errorMessages.value = error.response.data.error
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      emailValidator,
      loginForm,

      isPasswordVisible,

      email,
      password,
      loading,

      handleFormSubmit,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
